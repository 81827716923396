import React from 'react'

function Navbar(){
    return(
        <div>
            <nav>
                <img src="assets/ir_logo.svg" alt="ir_logo"></img>
            </nav>
        </div>
    )
}
export default Navbar;
