export const ROUTES = {
    DASHBOARD: '/',
    REPORTS: '/reports',
    REPORT_DETAILS: '/viewdetails/:id',
  };

export const ALL_HOURS = ["0", "00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24"];
export const DAYS_OF_WEEK = ["0", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
export const TODAY_HOURS = ["00:00", "03:00", "06:00", "09:00", "12:00", "15:00", "18:00", "21:00", "24:00"];
export const WEEK_DAYS = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export const activeTabForStatus = 'dashboard'

export const STATUS_IN_PROGRESS = 'in-progress';
export const STATUS_COMPLETED = 'completed';
export const STATUS_FAILED = 'failed';
export const STATUS_NO_DATA = 'no-data';

  