export const sessionStorageSet = (key, data) => {
    sessionStorage.setItem(key, JSON.stringify(data))
}
 
export const sessionStorageGet = (key) => {
    return JSON.parse(sessionStorage.getItem(key))
}
 
export const sessionStorageClear = () => {
    sessionStorage.clear()
}
 
export const sessionStorageRemove = (key) => {
    sessionStorage.removeItem(key)
}
