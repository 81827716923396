import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

function DonutChart ({title,containerClass,dataClass,total,alertCounts}) {

    const alertCountsData = alertCounts || {};
    const alertCountsArray = Object.keys(alertCountsData).map(key => ({
        label: key,
        count: alertCountsData[key]
    }));
    alertCountsArray.sort((a, b) => b.count - a.count);

    const topSignals = alertCountsArray.slice(0, 5);
    const otherSignalsCount = alertCountsArray.slice(5).reduce((sum, signal) => sum + signal.count, 0);   
    const labels = topSignals.map(signal => signal.label);
    const dataValues = topSignals.map(signal => signal.count);
    if (otherSignalsCount > 0) {
        labels.push('Others');
        dataValues.push(otherSignalsCount);
    }

    const data = {
        labels: labels,
        datasets: [
          {
            label: title,
            data: dataValues,
            backgroundColor: [
              '#000000',
              '#4152EC',
              '#7CCE53',
              '#9271F0',
              '#C651C8',
              '#BBBBBB'
            ],
            hoverBackgroundColor: [
              '#000000',
              '#4152EC',
              '#7CCE53',
              '#9271F0',
              '#C651C8',
              '#BBBBBB'
            ],
            borderWidth: 1,
          },
        ],
      };
   
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        cutout: '70%',
        plugins: {
        legend: {
          display: false,
          },
          datalabels: {
          color: '#FFFFFF',
          font: {
          weight: 'bold',
          size: 14,
          },
          formatter: (value, context) => {
            if (context.dataset.label === title && context.dataset.labels) {
                return context.dataset.labels[context.dataIndex] !== 'Others' ? value : '';
              }
            return value;
          },
        },
      },
    };
   
    
    return (
        <div className={containerClass}>
            <h2>{title}</h2>
            {total > 0 ?(
              <div>
                <div className="card-flex predicted-alerts-titles">
                      <h3>Total No. of {title.split(' ')[1]}</h3>
                       <span>{total}</span>
                  </div>
              <div className="in-flex">
              <div className="pi-chart">
                  <Doughnut data={data} options={options} />
              </div>
              <div className={dataClass}>
                     
                   <ul className="alert-parameters">
                      {topSignals.map((signal, index) => (
                          <li key={index}>{signal.label}</li>
                      ))}
                      {otherSignalsCount > 0 &&
                          <li>Others</li>
                      }
                  </ul>
              </div>
          </div>
          </div>
            ):(
              <div>
                <p className='no-data donutchart-no-data'>No Data Available</p>
              </div>
            )
            }      
        </div>
 
    );
};
 
export default DonutChart;