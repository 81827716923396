import axios from 'axios';

export const fetchLocomotiveData = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/Dashboard/LocomotivesCount/`);
        return response;
    } catch (error) {
        throw new Error('Error fetching locomotive data:', error);
    }
};

export const fetchPredictedAlertsData = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/Dashboard/PredictedAlerts/`);
        return response;
    } catch (error) {
        throw new Error('Error fetching predective alerts data:', error);
    }
}


export const fetchActualFaultsData = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/Dashboard/FaultsOccured/`);
        return response;
    } catch (error) {
        throw new Error('Error fetching faults data:', error);
    }
}

export const fetchTotalAlertsData = async (startDate, endDate, page, limit,selectedLocomotive) => {
    
    try {
        let url = `${process.env.REACT_APP_API_ENDPOINT}/Reports/TotalAlerts/?StartDate=${startDate}&EndDate=${endDate}&PageNumber=${page}&PageSize=${limit}&LocomotiveNumber=${selectedLocomotive}`;

        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        throw new Error('Error fetching total alerts data:', error);
    }
  };

export const fetchDataToCharts = async (startDateForCharts, endDateForCharts,selectedLocomotive) => {
    try {

        let url = `${process.env.REACT_APP_API_ENDPOINT}/Reports/Visualization/?StartDate=${startDateForCharts}&EndDate=${endDateForCharts}&LocomotiveNumber=${selectedLocomotive}`;
    
        const response = await axios.get(url);

        return response.data
    } catch (error) {
        throw new Error('Error fetching charts data:', error);
    }
}


export const fetchAlertDetails = async (alertId) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/Dashboard/Viewdetails/?generatedalertsid=${alertId}`);
        return response.data
    } catch (error) {
        throw new Error('Error fetching total alerts data:', error);
    }
}

export const fetchPopupAlert = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/Dashboard/PopupAlert/`);
        return response
    } catch (error) {
        throw new Error('Error fetching total alerts data:', error);
    }
}

export const fetchLocomotivesList = async () =>{
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/Reports/LocomotivesList/`);
        return response.data;
       
    } catch (error) {
        throw new Error('Error fetching locomotives list',error)
    }
}

export const fetchReportSharingPopup = async(startDate, endDate, selectedLocomotive) => {
    try{
  
        let url = `${process.env.REACT_APP_API_ENDPOINT}/Reports/SendEmail/?StartDate=${startDate}&EndDate=${endDate}&LocomotiveNumber=${selectedLocomotive}`;

        const response = await axios.get(url);
        return response;
    }catch(error){
        throw new Error('Error fetching report sharing popup',error)
     
    }
}

export const fetchDownloadPopup = async(startDate, endDate, selectedLocomotive) => {
    try{
        
        let url = `${process.env.REACT_APP_API_ENDPOINT}/Reports/DownloadPredictedAlertsData/?StartDate=${startDate}&EndDate=${endDate}&LocomotiveNumber=${selectedLocomotive}`;

        const response = await axios.get(url);
        return response;
    }catch(error){
        throw new Error('Error fetching report sharing popup',error)
        
    }
}

