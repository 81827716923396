import React from 'react'

function Tabs({activeTab, handleTabClick}){
    
    return(
        <div id="dashboard-section" className="menu-nav">
            <a className={activeTab === 'dashboard' ? 'active' : ''} onClick={() => handleTabClick('dashboard')}>Dashboard</a>
            <a className={activeTab === 'reports' ? 'active' : ''} onClick={() => handleTabClick('reports')}>Reports</a>
        </div>
    )
}
export default Tabs;
