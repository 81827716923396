import React from 'react'
import { BrowserRouter as Router, Routes,Route } from 'react-router-dom';
import DashboardComponent from '../Components/Dashboard/Dashboard';
import ReportsComponent from '../Components/Reports/Reports';
import Detailspage from '../Components/LocomotiveDetails/Detailspage';
import { ROUTES } from '../Components/Common/Constants';
 
function AppRouter() {
  return (
    <Router>
        <div>
            <Routes>
                <Route exact path={ROUTES.DASHBOARD} element={<DashboardComponent/>} />
                <Route path={ROUTES.REPORTS} element={<ReportsComponent/>}/>
                <Route path={ROUTES.REPORT_DETAILS} element={<Detailspage />} />
 
            </Routes>
        </div>
    </Router>
  )
}
export default AppRouter;