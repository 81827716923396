import React, { useEffect } from 'react'
import SlickSlider from '../../slickslider';
 
const AlertPopup = ({ show, onClose, children, closedSlides,latestAlerts }) => {
    useEffect(() => {
        if (show) {
          document.body.style.overflow = 'hidden';
        } else {
          document.body.style.overflow = 'auto';
        }
        return () => {
          document.body.style.overflow = 'auto';
        };
      }, [show]);
   
    return(
        <div className={`popup-modal ${show ? 'display-block' : 'display-none'}`}>
        <div className="alert-popup-main">
            {children}
            <SlickSlider latestAlerts={latestAlerts} onClose={onClose} closedSlides={closedSlides} />
        </div>
    </div>
    )
}
export default AlertPopup;