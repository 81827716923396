import React, { useEffect, useState,useRef } from 'react'
import { LineChart, BarChart, Line, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import '../../index.css';
import { formatDistanceToNow } from 'date-fns';
import { Stack, Pagination } from '@mui/material';
import { fetchDataToCharts, fetchTotalAlertsData, fetchAlertDetails, fetchLocomotivesList, fetchReportSharingPopup, fetchDownloadPopup } from '../../Utils/Api';
import { useNavigate } from 'react-router-dom';
import Popup from '../Popups/ReportSharingPopup';
import { ALL_HOURS } from '../Common/Constants';
import { DAYS_OF_WEEK } from '../Common/Constants';
import { TODAY_HOURS } from '../Common/Constants';
import { WEEK_DAYS } from '../Common/Constants';

function ReportsComponent({activeTab}){
    const [selectedRange, setSelectedRange] = useState('today');
    const [popupStatus, setPopupStatus] = useState('in-progress');
    const [dataForCharts, setDataForCharts] = useState([]);
    const [startDate,setStartDate] = useState('');
    const [endDate,setEndDate] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [totalAlertsCount, setTotalAlertsCount] = useState(0);
    const [locomotivesList, setLocomotivesList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [entriesPerPage, setEntriesPerPage] = useState(25);
    const [totalAlerts, setTotalAlerts] = useState([]);
    const [chartsData, setChartsData] = useState({});
    const [formattedData, setFormattedData] = useState([]);
    const [selectedLocomotive, setSelectedLocomotive] = useState('');
    const [dateLabel, setDateLabel] = useState({ this: "Today", last: "Yesterday" });
 
    const navigate = useNavigate()
    const SELECTED_RANGE_TODAY = 'today';
    const SELECTED_RANGE_THIS_WEEK = 'thisWeek';
    const SELECTED_RANGE_THIS_MONTH = 'thisMonth';
    const screenSize = 990
    const prevSelectedRangeRef = useRef(selectedRange);

    const [isMobileScreen, setIsMobileScreen] = useState(window.innerWidth <= screenSize);
    const [mobileArrowVisible, setMobileArrowVisible] = useState(false);
    const [accordionOpen, setAccordionOpen] = useState(false);
    useEffect(() => {
      const handleResize = () => {
          setIsMobileScreen(window.innerWidth <= screenSize);
      };
      window.addEventListener('resize', handleResize);
      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, []);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  const handleEntriesChange = (event) => {
    setEntriesPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const togglePopup1 = () => {     
    setAccordionOpen(prevState => !prevState); 
  };

    useEffect(() => {
      setMobileArrowVisible(isMobileScreen);
  }, [isMobileScreen]);

  useEffect(() => {
    const LocomotivesList = async() =>{
 
      try{
        const response = await fetchLocomotivesList();
        const result = response.LocomotivesList.map(locomotive => locomotive.LocomotiveNumber);
        setLocomotivesList(result);
      }catch(error){
        console.error('error fetching locomotives count',error)
      }
    }
    LocomotivesList();
 
  },[])

    useEffect(() => {
      const fetchDataForTable = async (startDate, endDate,page, limit,selectedLocomotive) => {
          try {
            const result = await fetchTotalAlertsData(startDate, endDate,page, limit,selectedLocomotive);
            if (result && Object.keys(result).length > 0) {
              setTotalAlerts(result['results']);
              setTotalAlertsCount(result['count']);
            } else {
              setTotalAlerts([]);
              setTotalAlertsCount(0);
            }
          } catch (error) {
              console.error("Error fetching data:", error);
          }
      };
 
      const fetchDataForCharts = async (startDateForCharts, endDateForCharts, selectedLocomotive) => {
          try {
              const result = await fetchDataToCharts(startDateForCharts, endDateForCharts, selectedLocomotive);
              
              setDataForCharts(result);
          } catch (error) {
              console.error("Error fetching data:", error);
          }
      };

      const getDateRange = () => {
          const now = new Date();

          const formatDate = (date) => {
              const d = new Date(date);
              const pad = (num) => String(num).padStart(2, '0');
              return `${d.getFullYear()}-${pad(d.getMonth() + 1)}-${pad(d.getDate())} ${pad(d.getHours())}:${pad(d.getMinutes())}:${pad(d.getSeconds())}`;
          };

          const setDateRange = (date, days, hours, minutes, seconds, milliseconds) => {
              const newDate = new Date(date);
              newDate.setDate(date.getDate() + days);
              newDate.setHours(hours, minutes, seconds, milliseconds);
              return formatDate(newDate);
          };

          let startDate, endDate, startDateForCharts, endDateForCharts;

          if (selectedRange === SELECTED_RANGE_TODAY) {
              startDate = setDateRange(now, 0, 0, 0, 0, 0);
              endDate = setDateRange(now, 0, 23, 59, 59, 999);
              startDateForCharts = setDateRange(now, 0, 0, 0, 0, 0);
              endDateForCharts = setDateRange(now, 0, 23, 59, 59, 999);
          } else if (selectedRange === SELECTED_RANGE_THIS_WEEK) {
              startDate = setDateRange(now, -now.getDay() + 1, 0, 0, 0, 0);
              endDate = setDateRange(now, -now.getDay() + 7, 23, 59, 59, 999);
              startDateForCharts = setDateRange(now, -now.getDay() - 6, 0, 0, 0, 0);
              endDateForCharts = setDateRange(now, 7 - now.getDay(), 23, 59, 59, 999);
          } else if (selectedRange === SELECTED_RANGE_THIS_MONTH) {
              const year = now.getFullYear();
              const month = now.getMonth();
              startDate = formatDate(new Date(year, month, 1, 0, 0, 0));
              endDate = formatDate(new Date(year, month + 1, 0, 23, 59, 59, 999));
              startDateForCharts = formatDate(new Date(year, month, 1, 0, 0, 0));
              endDateForCharts = formatDate(new Date(year, month + 1, 0, 23, 59, 59, 999));
          }

          return { startDate, endDate, startDateForCharts, endDateForCharts };
      };


        const { startDate, endDate, startDateForCharts, endDateForCharts } = getDateRange();
        setStartDate(startDate);
        setEndDate(endDate);
        fetchDataForTable(startDate, endDate,currentPage, entriesPerPage,selectedLocomotive);
        fetchDataForCharts(startDateForCharts, endDateForCharts, selectedLocomotive);
      
      
      }, [selectedRange, selectedLocomotive,currentPage, entriesPerPage]);

      const handleRangeChange = (e) => {
        setSelectedRange(e.target.value);
        setCurrentPage(1);
        setEntriesPerPage(25);
      };
   
        const handleSelectChange = (event) => {
          setSelectedLocomotive(event.target.value);
          setCurrentPage(1);
          setEntriesPerPage(25);
        };

    useEffect(() => {
        const fetchData = async () => {
          let Data;
          switch (selectedRange) {
            case 'today':
                Data = dataForCharts  
              break;

            case 'thisWeek':
                Data = dataForCharts
              break;

            case 'thisMonth':
                Data = dataForCharts
              break;

            default:
              return;
          }
          setChartsData(Data);
        };

    fetchData();
  }, [selectedRange, dataForCharts]);

      useEffect(() => {
        let formatted = [];
        switch (selectedRange) {
          case 'today':
            setDateLabel({ this: "Today", last: "Yesterday" });
            if (!chartsData || !chartsData["Line Chart"] || chartsData["Line Chart"].length === 0) {
              setFormattedData([]);
              return;
            }
            formatted = processDataForToday(chartsData["Line Chart"]);
            break;
          case 'thisWeek':
            setDateLabel({ this: "This Week", last: "Last Week" });
            if (!chartsData || !chartsData["Line Chart"] || chartsData["Line Chart"].length === 0) {
              setFormattedData([]);
              return;
            }
            formatted = processDataForThisWeek(chartsData["Line Chart"]);
            break;
          case 'thisMonth':
            setDateLabel({ this: "This Month", last: "Last Month" });
            if (!chartsData || !chartsData["Line Chart"] || chartsData["Line Chart"].length === 0) {
              setFormattedData([]);
              return;
            }
            formatted = processDataForThisMonth(chartsData["Line Chart"]);
            break;
          default:
            setFormattedData([]);
            return;
        }
        setFormattedData(formatted);  
      }, [chartsData, selectedRange]);

      const handleViewDetails = async (alertId) => {
        try {
            const details = await fetchAlertDetails(alertId);
            navigate(`/viewdetails/${alertId}`, { state: { alertDetails: details, fromDashboard: false } });
        } catch (error) {
            console.error("Error fetching alert details:", error);
        }
    };
   
     
    const processDataForToday = (data) => {
      const formattedToday = {};
      const formattedYesterday = {};
    
      if (data["Today"]) {
        Object.keys(data["Today"]).forEach(hour => {
          const formattedHour = hour.padStart(2, '0');
          formattedToday[formattedHour] = data["Today"][hour];
        });
      }
    
      if (data["Yesterday"]) {
        Object.keys(data["Yesterday"]).forEach(hour => {
          const formattedHour = hour.padStart(2, '0');
          formattedYesterday[formattedHour] = data["Yesterday"][hour];
        });
      }
    
      const lastAvailableHour = Math.max(...Object.keys(formattedToday).map(hour => parseInt(hour, 10)));
    
      const result = ALL_HOURS.map((hour, index) => {
        const isInvisibleZero = hour === "0";
        const isHourly = hour !== "0"; 
        return {
          name: isInvisibleZero ? "" : (hour === "24" ? "24:00" : `${hour}:00`),
          "This Day": isInvisibleZero ? 0 : (isHourly ? (parseInt(hour, 10) <= lastAvailableHour ? (formattedToday[hour] || 0) : null) : null),
          "Last Day": isInvisibleZero ? 0 : (isHourly ? (formattedYesterday[hour] || 0) : null)
        };
      });
    
      return result;
    };
      
    const processDataForThisWeek = (data) => {
      const thisWeekData = data["This Week"] || {};
      const lastWeekData = data["Last Week"] || {};
 
      let lastDayIndex = -1;
      DAYS_OF_WEEK.forEach((day, index) => {
          if (thisWeekData[day] !== undefined) {
              lastDayIndex = index;
          }
      });
 
      const result = DAYS_OF_WEEK.map((day, index) => ({
          name: day,
          "This Week": index === 0 ? 0 : (index <= lastDayIndex ? (thisWeekData[day] !== undefined ? thisWeekData[day] : 0) : null),
          "Last Week": index === 0 ? 0 : (lastWeekData[day] !== undefined ? lastWeekData[day] : 0),
      }));
 
      return result;
  };
     
   
      const processDataForThisMonth = (data) => {
      const daysInMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate();
      const dayLabels = ['0'].concat(Array.from({ length: daysInMonth }, (_, i) => (i + 1).toString().padStart(2, '0')));
      const result = Array.from({ length: daysInMonth + 1 }, (_, i) => ({
        name: i === 0 ? "0" : i.toString().padStart(2, '0'),
        "This Month": i === 0 ? 0 : null,
        "Last Month": i === 0 ? 0 : 0
      }));

      let lastThisMonthWeekIndex = 0;

      if (data["This Month"]) {
        Object.keys(data["This Month"]).forEach(date => {
        const day = new Date(date).getDate();
        if (day >= 1 && day <= daysInMonth) {
          const index = day;
          result[index]["This Month"] = (result[index]["This Month"] || 0) + data["This Month"][date];
          lastThisMonthWeekIndex = Math.max(lastThisMonthWeekIndex, index);
        }
      });
      }

  if (data["Last Month"]) {
    Object.keys(data["Last Month"]).forEach(date => {
      const day = new Date(date).getDate();
      if (day >= 1 && day <= daysInMonth) {
        const index = day;
        result[index]["Last Month"] += data["Last Month"][date] || 0;
      }
    });
  }

  result.forEach((day, index) => {
    if (day["This Month"] === null) {
      if (index <= lastThisMonthWeekIndex) {
        day["This Month"] = 0;
      } else {
        day["This Month"] = null;
      }
    }
  });

  return { formattedData: result, dayLabels };
};

if (prevSelectedRangeRef.current !== selectedRange) {
  setEntriesPerPage(25);
  setCurrentPage(1);
  prevSelectedRangeRef.current = selectedRange;
}
 
 
  const useScreenSize = () => {
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= screenSize);
  
    useEffect(() => {
      const handleResize = () => setIsSmallScreen(window.innerWidth <= screenSize);
      
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return isSmallScreen;
  };
      const isSmallScreen = useScreenSize();
      const BarGraph = dataForCharts["Bar Graph"] || [];
 
      const sortedData = [...BarGraph].sort((a, b) => b.Alerts - a.Alerts);
      const topData = isSmallScreen ? sortedData.slice(0, 5) : sortedData;
    
      const BarformattedData = topData.map(item => ({
        name: item.LocomotiveNumber,
        Alerts: item.Alerts || 0,
        Faults: item.Faults || 0
      }));


    const indexOfLastEntry = currentPage * entriesPerPage;
    const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;

      const getHours = (range) => {
        if (range === 'today') {
          return TODAY_HOURS;
        }
        if (range === 'thisWeek') {
          return WEEK_DAYS;
        }
        return [];
      };
      
      const XAxisTickFormatter = (tick, selectedRange) => {
        const visibleTicks = getHours(selectedRange);
        return visibleTicks.includes(tick) ? tick : '';
      };

      const handleActionClick = async (action,totalAlerts) => {

        try {
          
          if (totalAlerts.length === 0) {
            setPopupStatus('no-data');
            setShowPopup(true);
            setTimeout(() => {
                setShowPopup(false);
            }, 5000);
            return;
          }
          setPopupStatus('in-progress');
          setShowPopup(true);
          let response;

          if(action === 'Share'){
             response = await fetchReportSharingPopup(startDate, endDate, selectedLocomotive);
          }else if (action === 'Download'){
            response = await fetchDownloadPopup(startDate, endDate, selectedLocomotive)
            const  downloadUrl = `${process.env.REACT_APP_API_ENDPOINT}/Reports/DownloadPredictedAlertsData/?StartDate=${encodeURIComponent(startDate)}&EndDate=${encodeURIComponent(endDate)}&LocomotiveNumber=${encodeURIComponent(selectedLocomotive)}`;
            window.location.href = downloadUrl;

          }
          
          if (response.status === 200) {
            setTimeout(() => {
              setPopupStatus('completed');
              setTimeout(() => {
                setShowPopup(false);
              }, 5000);
            }, 5000);
          } else {
            setTimeout(() => {
              setPopupStatus('failed');
              setTimeout(() => {
                setShowPopup(false);
              }, 5000);
            }, 5000);
          }
        } catch (error) {
          setTimeout(() => {
            setPopupStatus('failed');
            setTimeout(() => {
              setShowPopup(false);
            }, 5000);
          }, 5000);
          console.error('Error fetching report sharing data:', error);
        }
      };

    const isAllLocomotivesSelected = selectedLocomotive === "";
    return (
    <div id='reports-data' className={activeTab === 'reports' ? 'tab-content' : 'tab-content hidden'}>
      <div className='dpdw-flex'>
        <select name="" id="data-sorting" onChange={handleRangeChange}>
          <option value="today">Today</option>
          <option value="thisWeek">This Week</option>
          <option value="thisMonth">This Month</option>
        </select>
        <select name="" id="locomotives-selection" value={selectedLocomotive}
                onChange={handleSelectChange}>
                <option value="">All Locomotives</option>
                {locomotivesList.map(item => (
                    <option key={item} value={item.toString()}>{item}</option>
                ))}
            </select>
      </div>

      {selectedRange === 'today' || selectedRange === 'thisWeek' ? (
        <>
        <div className="in-flex">
        <div className='alerts'>
          <div className="predicted-alerts reports-bar-graph">
            <div className='reports-flex reported-alerts'>
              <h2>Total Predicted Alerts</h2>
              <span className='reports-title-seperator'>|</span>
              <p className='total-locomotives'>{dateLabel.this}</p>
              <p className='total-locomotives week-locomotives'>{dateLabel.last}</p>
            </div>
            <div className='alerts-container'>
              {formattedData.length > 0 ?(
            <ResponsiveContainer width="100%" height={400}>
              <LineChart data={formattedData} margin={{ top: 30, right: 25, left: -35, bottom: 5 }}>
                <XAxis
                className='barchat-x'
                dataKey="name"
                tickLine={false}
                tickFormatter={(tick) => XAxisTickFormatter(tick, selectedRange)}
                axisLine={false}
                interval={0} 
                />
                <YAxis className='barchat-y' axisLine={false} tickLine={false} />
                <Tooltip />
                <Line
                  type="monotone"
                  dataKey={selectedRange === 'today' ? "This Day" : "This Week"}
                  stroke="#000"
                  dot={false}
                  connectNulls={true} 
                />
                <Line
                type="monotone"
                dataKey={selectedRange === 'today' ? "Last Day" : "Last Week"}
                stroke="#A8C5DA"
                strokeDasharray="3 3"
                dot={false}
                connectNulls={true} 
                />
                </LineChart>
              </ResponsiveContainer>
              ):(
                <div>
                  <p className='no-data donutchart-no-data bargraph-no-data'>No Data Available</p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='alerts'>
          <div className="predicted-alerts reports-bar-graph">
            <div className='reports-flex reported-alerts'>
              <h2>{isAllLocomotivesSelected ? 'Top 5 Locomotives' : `LOCOMOTIVE (${selectedLocomotive})`}</h2>
              <span>|</span>
              <p className='total-locomotives alert-locomotives'>Alerts</p>
              <p className='total-locomotives faults-locomotives'>Faults</p>
            </div>
            <div className='locomotives-container'>
              {BarformattedData.length > 0 ?(
              <ResponsiveContainer width="100%" height={400}>
                <BarChart data={BarformattedData} margin={{ top: 30, right: 25, left: -35, bottom: 5 }}>
 
                  <XAxis className='barchat-x' dataKey="name" axisLine={false} tickLine={false} />
                  <YAxis className='barchat-y' axisLine={false} tickLine={false} />
                  <Tooltip />
                  <Bar className='alerts-bar' dataKey="Alerts" fill="#E5ECF6" shape={<CustomBar />} label={<CustomLabel />} />
                  <Bar className='faults-bar' dataKey="Faults" fill="#95A4FC" shape={<CustomBar />} label={<CustomLabel />} />
 
                </BarChart>
              </ResponsiveContainer>
              ):(
                <div>
                  <p className='no-data donutchart-no-data bargraph-no-data'>No Data Available</p>
                </div>
              )}
            </div>
          </div>
         
        </div>
       
      </div>
        </>
      ):selectedRange === 'thisMonth' ? (
      <>
      <div className='alerts month-alerts'>
          <div className="predicted-alerts reports-bar-graph">
            <div className='reports-flex reported-alerts'>
              <h2>Total Predicted Alerts</h2>
              <span className='reports-title-seperator'>|</span>
              <p className='total-locomotives'>{dateLabel.this}</p>
              <p className='total-locomotives week-locomotives'>{dateLabel.last}</p>
            </div>
            <div className='alerts-container'>
            {formattedData?.formattedData?.length > 0 ? (
            <ResponsiveContainer width="100%" height={400}>
              <LineChart data={formattedData.formattedData} margin={{ top: 30, right: 25, left: -35, bottom: 5 }}>
              <XAxis 
                dataKey="name" 
                ticks={formattedData.dayLabels} 
                tickFormatter={(tick) => tick === "0" ? "" : tick} 
                axisLine={false} 
                tickLine={false} 
              />
              <YAxis className='barchat-y' axisLine={false} tickLine={false} />
              <Tooltip />
              <Line type="monotone" dataKey={selectedRange === 'today' ? "This Day" : selectedRange === 'thisWeek' ? "This Week" : "This Month"} stroke="#000" />
              <Line type="monotone" dataKey={selectedRange === 'today' ? "Last Day" : selectedRange === 'thisWeek' ? "Last Week" : "Last Month"} stroke="#A8C5DA" strokeDasharray="3 3" />
              </LineChart>
              </ResponsiveContainer>
              ):(
                <div>
                  <p className='no-data donutchart-no-data bargraph-no-data'>No Data Available</p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='alerts month-alerts'>
          <div className="predicted-alerts reports-bar-graph">
            <div className='reports-flex reported-alerts'>
              <h2>{isAllLocomotivesSelected ? `Top ${isSmallScreen ? 5 : 10} Locomotives` : `Locomotive (${selectedLocomotive})`}</h2>
              <span>|</span>
              <p className='total-locomotives alert-locomotives'>Alerts</p>
              <p className='total-locomotives faults-locomotives'>Faults</p>
            </div>
            <div className='locomotives-container'>
              {BarformattedData.length > 0 ? (
              <ResponsiveContainer width="100%" height={400}>
                <BarChart data={BarformattedData} margin={{ top: 30, right: 25, left: -35, bottom: 5 }}>
 
                  <XAxis className='barchat-x' dataKey="name" axisLine={false} tickLine={false} />
                  <YAxis className='barchat-y' axisLine={false} tickLine={false} />
                  <Tooltip />
                  <Bar className='alerts-bar' dataKey="Alerts" fill="#E5ECF6" shape={<CustomBar />} label={<CustomLabel />} />
                  <Bar className='faults-bar' dataKey="Faults" fill="#95A4FC" shape={<CustomBar />} label={<CustomLabel />} />
 
                </BarChart>
              </ResponsiveContainer>
              ):(
                <div>
                  <p className='no-data donutchart-no-data bargraph-no-data'>No Data Available</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </>):null}

   <div className='predicted-alerts dashboard-alerts'>
          <div className='in-flex share-flex' onClick={togglePopup1}>
              <div className='reports-accordion'>
                  <h2>Total Predicted Alerts</h2>
                  {mobileArrowVisible && (
                  <img src={accordionOpen ? 'assets/up-arrow.png' : 'assets/down-arrow.png'} alt="arrow-icon" className="arrow-icon" />
                  )}
              </div>
          <div className='download-flex'>
          <div>
              <button className='share-button' onClick={() => handleActionClick('Share',totalAlerts)}>
                <img src="assets/share-icon.png" alt="share-icon" />
                <span>Share</span>
              </button>
              <Popup show={showPopup} status={popupStatus}>
 
              </Popup>
            </div>
            <button className='download-button' onClick={() => handleActionClick('Download',totalAlerts)}>
              <img src="assets/download-icon.png" alt="download-icon" />
              <span>Download</span>
            </button>
          </div>
        </div>
        {!isMobileScreen && (
                totalAlerts.length > 0 ? (
                  <div>

                      <table>
                        <thead>
                          <tr className='alert-titles'>
                            <th>Loco Number</th>
                            <th className='loco-table-head'>Sub Systems</th>
                            <th>Location</th>
                            <th className='loco-table-head'>Disturbance Message (DDS)</th>
                            <th>Prediction Time</th>
                            <th className='loco-table-head'>Status</th>
                            <th className='loco-table-head'>Action</th>
                          </tr>
                        </thead>
                        <tbody className='reports-list'>
                        {totalAlerts.map(fault => (
                            <tr key={fault.PredictedAlertId} className='alert-table'>
                              <td>{fault.LocomotiveNumber}</td>
                              <td className='loco-table-head'>{fault.Subsystem}</td>
                              <td>{fault.Location}</td>
                              <td className='loco-table-head'>{fault.DisturbanceMessage}</td>
                              <td>
                                <div className="card-flex alert-time">
                                  {/* <img src="assets/calendar-icon.png" alt="calendar-icon" /> */}
                                  <p>{fault.AlertTime ? `${Math.floor((new Date() - new Date(fault.AlertTime)) / (1000 * 60 * 60)) || Math.floor((new Date() - new Date(fault.AlertTime)) / (1000 * 60)) || Math.floor((new Date() - new Date(fault.AlertTime)) / 1000)} ${Math.floor((new Date() - new Date(fault.AlertTime)) / (1000 * 60 * 60)) ? 'hour' : Math.floor((new Date() - new Date(fault.AlertTime)) / (1000 * 60)) ? 'minute' : 'second'}${Math.floor((new Date() - new Date(fault.AlertTime)) / (1000 * 60 * 60)) > 1 || Math.floor((new Date() - new Date(fault.AlertTime)) / (1000 * 60)) > 1 || Math.floor((new Date() - new Date(fault.AlertTime)) / 1000) > 1 ? 's' : ''} ago` : ''}</p>
                                </div>
                              </td>
                              <td className={`loco-table-head status ${fault.Status.toLowerCase()}`}>{fault.Status}</td>
                              <td className='loco-table-head'>
                              <a className='view-details underline-animation' onClick={() => handleViewDetails(fault.PredictedAlertId)}>View Details</a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div className='pagination-flex'>
                      <div className='pagination-dpdw'>
                        <select value={entriesPerPage} onChange={handleEntriesChange} className='pagination-select'>
                          <option value="25">25</option>
                          <option value="50">50</option>
                        </select>
                        <p className='pagination-desc'>Showing {indexOfFirstEntry + 1} to {Math.min(indexOfLastEntry, totalAlertsCount)} of {totalAlertsCount} entries</p>
                      </div>
                      <div className='pagination-nav'>
                        <Stack spacing={2}>
                        <Pagination
                            count={Math.ceil(totalAlertsCount / entriesPerPage)}
                            page={currentPage}
                            onChange={handlePageChange}
                            siblingCount={0}
                            defaultPage={1}
                            variant="outlined"
                            shape="rounded"
                          />
                        </Stack>
                      </div>
                      </div>
                  </div>
                 
        ) : (
          <div className='tabular-no-data'>
              <p className='no-data'>No Data Available</p>
          </div>
      )
      )}

{accordionOpen && isMobileScreen && (
                totalAlerts.length > 0 ? (
                  <div>

                      <table>
                      <thead>
                        <tr className='alert-titles reports-title position-unset'>
                          <th className='loco-table-resp-head'>Loco Number</th>
                          <th className='loco-table-head'>Sub Systems</th>
                          <th className='loco-table-resp-head'>Location</th>
                          <th className='loco-table-head'>Disturbance Message (DDS)</th>
                          <th className='loco-table-resp-head'>Prediction Time</th>
                          <th className='loco-table-head'>Status</th>
                          <th className='loco-table-head'>Action</th>
                        </tr>
                      </thead>
                      <tbody >
                      {totalAlerts.map(fault => (
                        <a className='td-btn' onClick={() => handleViewDetails(fault.PredictedAlertId)}>
                          <tr key={fault.PredictedAlertId} className={fault.Status === 'Critical' ? 'critical-border critical-border' : 'critical-border minor-border' }>
                            <td className='loco-table-resp-head'>{fault.LocomotiveNumber}</td>
                            <td className='loco-table-head'>{fault.Subsystem}</td>
                            <td className='loco-table-resp-head'>{fault.Location}</td>
                            <td className='loco-table-head'>{fault.DisturbanceMessage}</td>
                            <td className='loco-table-resp-head'>
                              <div className="card-flex alert-time">
                                {/* <img src="assets/calendar-icon.png" alt="calendar-icon" /> */}
                                <p>{fault.AlertTime ? `${Math.floor((new Date() - new Date(fault.AlertTime)) / (1000 * 60 * 60)) || Math.floor((new Date() - new Date(fault.AlertTime)) / (1000 * 60)) || Math.floor((new Date() - new Date(fault.AlertTime)) / 1000)} ${Math.floor((new Date() - new Date(fault.AlertTime)) / (1000 * 60 * 60)) ? 'hour' : Math.floor((new Date() - new Date(fault.AlertTime)) / (1000 * 60)) ? 'minute' : 'second'}${Math.floor((new Date() - new Date(fault.AlertTime)) / (1000 * 60 * 60)) > 1 || Math.floor((new Date() - new Date(fault.AlertTime)) / (1000 * 60)) > 1 || Math.floor((new Date() - new Date(fault.AlertTime)) / 1000) > 1 ? 's' : ''} ago` : ''}</p>
            
                              </div>
                            </td>
                            <td className={`loco-table-head status ${fault.Status.toLowerCase()}`}>{fault.Status}</td>
                            <td className='loco-table-head'>
                            <a className='view-details underline-animation' onClick={() => handleViewDetails(fault.PredictedAlertId)}>View Details</a>
                            </td>
                          </tr>
                          </a>
                        ))}
                        
                      </tbody>
                    </table>
                    <div className='pagination-flex'>
                      <div className='pagination-dpdw'>
                        <select value={entriesPerPage} onChange={handleEntriesChange} className='pagination-select'>
                          <option value="25">25</option>
                          <option value="50">50</option>
                        </select>
                        <p className='pagination-desc'>Showing {indexOfFirstEntry + 1} to {Math.min(indexOfLastEntry, totalAlertsCount)} of {totalAlertsCount} entries</p>
                      </div>
                      <div className='pagination-nav'>
                        <Stack spacing={2}>
                        <Pagination
                            count={Math.ceil(totalAlertsCount / entriesPerPage)}
                            page={currentPage}
                            onChange={handlePageChange}
                            siblingCount={0}
                            defaultPage={1}
                            variant="outlined"
                            shape="rounded"
                          />
                        </Stack>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className='tabular-no-data'>
                      <p className='no-data'>No Data Available</p>
                  </div>
              )
          )}     
      </div>
     
    </div>
  )
}
 
const CustomBar = (props) => {
  const { fill, x, y, width, height } = props;

  const adjustedWidth = width < 10 ? 10 : width;

  return (
    <g>
      <rect x={x} y={y} width={adjustedWidth} height={height} fill={fill} rx={8} />
    </g>
  );
};

 
const CustomLabel = (props) => {
  const { x, y, width, value } = props;

  return (
    <text x={x + width / 2} y={y - 10} fill="#000" textAnchor="middle">
      {value}
    </text>
  );
};

 
 
export default ReportsComponent;