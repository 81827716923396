import React, {useEffect} from 'react'
import { STATUS_IN_PROGRESS,STATUS_COMPLETED,STATUS_FAILED,STATUS_NO_DATA } from '../Common/Constants';
 
const ReportsPopup = ({ show, status, children }) => {
    useEffect(() => {
        if (show) {
          document.body.style.overflow = 'auto';
        } else {
          document.body.style.overflow = 'auto';
        }
        return () => {
          document.body.style.overflow = 'auto';
        };
      }, [show]);
      const getStatusContent = () => {
        switch (status) {
          case STATUS_IN_PROGRESS:
            return (
              <div className='btn-flex progress-btn'>
                <img src="assets/inprogress.png" alt="in-progress" />
                <p>In Progress...</p>
              </div>
            );
          case STATUS_COMPLETED:
            return (
              <div className='btn-flex completed-btn'>
                <img src="assets/completed.png" alt="completed" />
                <p>Completed</p>
              </div>
            );
          case STATUS_FAILED:
            return (
              <div className='btn-flex failed-btn'>
                <img src="assets/failed.png" alt="failed" />
                <p>Failed</p>
              </div>
            );
            case STATUS_NO_DATA:
              return (
                <div className='btn-flex failed-btn'>
                  <img src="assets/failed.png" alt="no-data" />
                  <p>No Data Available</p>
              </div>
            );
          default:
            return null;
        }
      };
    return (
      <div className={`success-popup-modal ${show ? 'display-block' : 'success-hide'}`}>
      <div className="success-popup-main success-popup">
        {getStatusContent()}
      </div>
    </div>
    )
};
export default ReportsPopup;