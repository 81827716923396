import React, { useEffect } from 'react';
import { fetchAlertDetails } from '../../Utils/Api';
import {useNavigate} from 'react-router-dom'
import { formatDistanceToNow } from 'date-fns';
 
const FaultTable = ({ predictedAlertsDetails, toggleAccordion, accordionOpen, isMobileScreen, mobileArrowVisible,showActionColumn ,title}) => {
    const navigate = useNavigate();

    useEffect(() => {
        const addClassesOnPriority = () => {
            const statusTags = document.getElementsByClassName("status");
            for (let i = 0; i < statusTags.length; i++) {
                if (statusTags[i].textContent.trim() === "Critical") {
                    statusTags[i].classList.add("critical");
                } else if (statusTags[i].textContent.trim() === "Minor") {
                    statusTags[i].classList.add("minor");
                }
            }
        };

        addClassesOnPriority();
    }, [predictedAlertsDetails]);


    const handleViewDetails = async (alertId) => {
        try {
            const details = await fetchAlertDetails(alertId);
            navigate(`/viewdetails/${alertId}`, { state: { alertDetails: details, fromDashboard: true } });
        } catch (error) {
            console.error("Error fetching alert details:", error);
        }
    };

    return (
        <div className="predicted-alerts dashboard-alerts">
            <div className='accordian-flex accordion-header' onClick={toggleAccordion}>
                <h2>{title} (Tabular View)</h2>
                {mobileArrowVisible && (
                    <img src={accordionOpen ? 'assets/up-arrow.png' : 'assets/down-arrow.png'} alt="arrow-icon"
                        className="arrow-icon" />
                )}
            </div>
            {accordionOpen && isMobileScreen && (
                predictedAlertsDetails.length > 0 ? (
                    <table>
                        <thead>
                            <tr className='alert-titles position-unset'>
                                <th className='loco-table-resp-head'>Loco Number</th>
                                <th className='loco-table-head'>Sub Systems</th>
                                <th className='loco-table-resp-head'>Location</th>
                                <th className='loco-table-resp-head'>{title === 'Predicted Alerts' ? 'Prediction Time' : 'Alert Time'}</th>
                                <th className='loco-table-head'>Status</th>
                                {showActionColumn && <th className='loco-table-head'>Action</th>}
                            </tr>
                        </thead>
                        <tbody>
                        {predictedAlertsDetails.map(alert => (
                                <a className='td-btn' onClick={title === 'Predicted Alerts' ? () => handleViewDetails(alert.PredictedAlertId) : undefined}>
                                <tr key={alert.PredictedAlertId} className={alert.Status === 'Critical' ? 'critical-border critical-border' : 'critical-border minor-border' }>
                                    <td className='loco-table-resp-head'>{alert.LocomotiveNumber}</td>
                                    <td className='loco-table-head'>{alert.Subsystem}</td>
                                    <td className='loco-table-resp-head'>{alert.Location}</td>
                                    <td className='loco-table-resp-head'>
                                        <div className="card-flex alert-time">
                                            <img src="assets/clock-icon.png" alt="clock-icon" />
                                            <p>{alert.AlertTime ? `${Math.floor((new Date() - new Date(alert.AlertTime)) / (1000 * 60 * 60)) || Math.floor((new Date() - new Date(alert.AlertTime)) / (1000 * 60)) || Math.floor((new Date() - new Date(alert.AlertTime)) / 1000)} ${Math.floor((new Date() - new Date(alert.AlertTime)) / (1000 * 60 * 60)) ? 'hour' : Math.floor((new Date() - new Date(alert.AlertTime)) / (1000 * 60)) ? 'minute' : 'second'}${Math.floor((new Date() - new Date(alert.AlertTime)) / (1000 * 60 * 60)) > 1 || Math.floor((new Date() - new Date(alert.AlertTime)) / (1000 * 60)) > 1 || Math.floor((new Date() - new Date(alert.AlertTime)) / 1000) > 1 ? 's' : ''} ago` : ''}</p>
                                        </div>
                                    </td>
                                    <td className='status loco-table-head'>{alert.Status}</td>
                                </tr>
                                </a>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <div className='tabular-no-data'>
                        <p className='no-data'>No Data Available</p>
                    </div>
                )
            )}
            {!isMobileScreen && (
                predictedAlertsDetails.length > 0 ? (
                <table>
                    <thead>
                        <tr className='alert-titles'>
                            <th>Loco Number</th>
                            <th className='loco-table-head'>Sub Systems</th>
                            <th>Location</th>
                            <th>{title === 'Predicted Alerts' ? 'Prediction Time' : 'Alert Time'}</th>
                            <th className='loco-table-head'>Status</th>
                            {showActionColumn && <th className='loco-table-head'>Action</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {predictedAlertsDetails.map(alert => (
                            <tr key={alert.PredictedAlertId} className='alert-table'>
                                <td>{alert.LocomotiveNumber}</td>
                                <td className='loco-table-head'>{alert.Subsystem}</td>
                                <td>{alert.Location}</td>
                                <td>
                                    <div className="card-flex alert-time">
                                        <img src="assets/clock-icon.png" alt="clock-icon" />
                                        <p>{alert.AlertTime ? `${Math.floor((new Date() - new Date(alert.AlertTime)) / (1000 * 60 * 60)) || Math.floor((new Date() - new Date(alert.AlertTime)) / (1000 * 60)) || Math.floor((new Date() - new Date(alert.AlertTime)) / 1000)} ${Math.floor((new Date() - new Date(alert.AlertTime)) / (1000 * 60 * 60)) ? 'hour' : Math.floor((new Date() - new Date(alert.AlertTime)) / (1000 * 60)) ? 'minute' : 'second'}${Math.floor((new Date() - new Date(alert.AlertTime)) / (1000 * 60 * 60)) > 1 || Math.floor((new Date() - new Date(alert.AlertTime)) / (1000 * 60)) > 1 || Math.floor((new Date() - new Date(alert.AlertTime)) / 1000) > 1 ? 's' : ''} ago` : ''}</p>
                                    </div>
                                </td>
                                <td className='status loco-table-head'>{alert.Status}</td>
                                {showActionColumn && (
                                    <td className='loco-table-head'>
                                        <a className='view-details' onClick={() => handleViewDetails(alert.PredictedAlertId)}>View Details</a>
                                    </td>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <div className='tabular-no-data'>
                    <p className='no-data'>No Data Available</p>
                </div>
            )
            )}
        </div>
    );
};

export default FaultTable;
