import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Navbar from '../Common/Navbar'
import { fetchAlertDetails } from '../../Utils/Api';


function Detailspage() {
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [alertDetails, setAlertDetails] = useState(location.state?.alertDetails ? location.state.alertDetails[0] : null);
    const fromDashboard = location.state?.fromDashboard;
    const [showUnderscore, setShowUnderscore] = useState(true);

    useEffect(() => {
        if (!alertDetails) {
            const fetchData = async () => {
                try {
                    const details = await fetchAlertDetails(id);
                    setAlertDetails(details[0]);
                } catch (error) {
                    console.error("Error fetching alert details:", error);
                    navigate('/');
                }
            };
            fetchData();
        }
    }, [alertDetails, id, navigate]);

    useEffect(() => {
        const handleResize = () => {
            setShowUnderscore(window.innerWidth > 476);
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleBreadcrumbClick = () => {
        navigate('/', { state: { activeTab: fromDashboard ? 'dashboard' : 'reports' } });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <Navbar />
            <div>
                <ul className="breadcrumb">
                    <li onClick={handleBreadcrumbClick} >
                        <a>{fromDashboard ? 'Dashboard' : 'Reports'}</a>
                    </li>
                    <li>Locomotive number - {alertDetails?.LocomotiveNumber}</li>
                </ul>
            </div>
            <div className='loco-number-details'>
                <h2>Locomotive Number <span>- {alertDetails?.LocomotiveNumber}</span></h2>
            </div>
            <div className='loco-details-info'>
                <div className='loco-details-img'>
                    <img src='assets/detail-locomotive.png' alt='locomotive-image'></img>
                </div>
                <ul>
                    <li>
                        <span className='locomotive-heads'>Location</span>
                        <span className='details-colon'>:</span>
                        <span className='loco-detail-desc'>{alertDetails?.Location}</span>
                    </li>
                    <li>
                        <span className='locomotive-heads'>Prediction Time </span>
                        <span className='details-colon'>:</span>
                        <span className='loco-detail-desc'>
                            {alertDetails?.AlertTime ? (
                                <>
                                    {`${String(new Date(alertDetails.AlertTime).getDate()).padStart(2, '0')}-${String(new Date(alertDetails.AlertTime).getMonth() + 1).padStart(2, '0')}-${new Date(alertDetails.AlertTime).getFullYear()}`}
                                    {showUnderscore && <span className='underscore'>_</span>}
                                    <br className='break-alert-time' />
                                    {`${String(new Date(alertDetails.AlertTime).getHours()).padStart(2, '0')}:${String(new Date(alertDetails.AlertTime).getMinutes()).padStart(2, '0')}:${String(new Date(alertDetails.AlertTime).getSeconds()).padStart(2, '0')}`}
                                </>
                            ) : ''}
                        </span>
                    </li>
                    <li>
                        <span className='locomotive-heads'>Prediction Window</span>
                        <span className='details-colon'>:</span>
                        <span className='loco-detail-desc'>{alertDetails?.Predictiontime}</span>
                    </li>
                    <li>
                        <span className='locomotive-heads'>Fault Code</span>
                        <span className='details-colon'>:</span>
                        <span className='loco-detail-desc'>{alertDetails?.FaultCode}</span>
                    </li>
                    <li>
                        <span className='locomotive-heads'>Message ID</span>
                        <span className='details-colon'>:</span>
                        <span className='loco-detail-desc'>{alertDetails?.MessageID}</span>
                    </li>
                    <li>
                        <span className='locomotive-heads'>Disturbance Message (DDS)</span>
                        <span className='details-colon'>:</span>
                        <span className='loco-detail-desc'>{alertDetails?.DisturbanceMessage}</span>
                    </li>
                    <li>
                        <span className='locomotive-heads'>SUB</span>
                        <span className='details-colon'>:</span>
                        <span className='loco-detail-desc'>{alertDetails?.Subsystem}</span>
                    </li>
                    <li>
                        <span className='locomotive-heads'>Real Time Data Head</span>
                        <span className='details-colon'>:</span>
                        <span className='loco-detail-desc'>{alertDetails?.RealTimeDataHead}</span>
                    </li>
                    <li>
                        <span className='locomotive-heads'>Signal Name</span>
                        <span className='details-colon'>:</span>
                        <span className='loco-detail-desc'>{alertDetails?.SignalName}</span>
                    </li>
                    <li>
                        <span className='locomotive-heads'>Action Needed</span>
                        <span className='details-colon'>:</span>
                        <span className='loco-detail-desc'>{alertDetails?.ActionNeeded}</span>
                    </li>
                </ul>
            </div>
        </div>
    );
}
export default Detailspage;