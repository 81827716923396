import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import { fetchAlertDetails } from './Utils/Api';

const SlickSlider = ({ latestAlerts, onClose, closedSlides, setClosedSlides }) => {

    const [currentSlide, setCurrentSlide] = useState(0);
    const navigate = useNavigate()
    const settings = {
        dots: false,
        infinite: false,
        speed: 700,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (current) => setCurrentSlide(current)
    };

    const [showUnderscore, setShowUnderscore] = useState(true);

    useEffect(() => {
        const handleResize = () => {
            setShowUnderscore(window.innerWidth > 768);
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleViewDetails = async (alertId) => {
        try {
            const details = await fetchAlertDetails(alertId);
            navigate(`/viewdetails/${alertId}`, { state: { alertDetails: details, fromDashboard: true } });
        } catch (error) {
            console.error("Error fetching alert details:", error);
        }
    };
    const handleCloseSlide = (alertId) => {
        onClose(alertId);
    };

    return (
        <div>
            <Slider {...settings}>
                {latestAlerts.map((alert, index) => (
                    <div key={index}>
                        <div className="popup-content">
                            <img src="assets/popup-logo.png" alt="popup-logo" />
                            <h2>A new problem in the Locomotive</h2>
                            <ul>
                                <li>
                                    <span className='loco-details'>Locomotive Number</span>
                                    <span className='colon-symbol'>:</span>
                                    <span className='loco-number'>{alert.LocomotiveNumber}</span>
                                </li>
                                <li>
                                    <span className='loco-details'>Location</span>
                                    <span className='colon-symbol'>:</span>
                                    <span className='loco-number'>{alert.Location}</span>
                                </li>
                                <li>
                                    <span className='loco-details'>Disturbance Message (DDS)</span>
                                    <span className='colon-symbol'>:</span>
                                    <span className='loco-number'>{alert.DDS}</span>
                                </li>
                                <li>
                                    <span className='loco-details'>Prediction Time</span>
                                    <span className='colon-symbol'>:</span>
                                    <span className='loco-number'>
                                        {alert?.AlertTime ? (
                                            <>
                                                {`${String(new Date(alert.AlertTime).getDate()).padStart(2, '0')}-${String(new Date(alert.AlertTime).getMonth() + 1).padStart(2, '0')}-${new Date(alert.AlertTime).getFullYear()}`}
                                                {showUnderscore && <span className='underscore'>_</span>}
                                                <br className='break-alert-time slider-alert-time' />
                                                {`${String(new Date(alert.AlertTime).getHours()).padStart(2, '0')}:${String(new Date(alert.AlertTime).getMinutes()).padStart(2, '0')}:${String(new Date(alert.AlertTime).getSeconds()).padStart(2, '0')}`}
                                            </>
                                        ) : ''}
                                    </span>
                                </li>
                                <li>
                                    <span className='loco-details'>Prediction Window</span>
                                    <span className='colon-symbol'>:</span>
                                    <span className='loco-number'>{alert.Predictiontime}</span>
                                </li>
                            </ul>
                            <div className="popup-buttons">
                                <button className='view-details-btn' onClick={() => handleViewDetails(alert.PredictedAlertId)}>View Details</button>
                                <button className='close-btn' onClick={() => handleCloseSlide(alert.PredictedAlertId)}>Close</button>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
            <div className="slide-counter">
                {currentSlide + 1}/{latestAlerts.length}
            </div>
        </div>
    );
};

export default SlickSlider;